$headerHeightSml: 72px;
$headerHeightLrg: 98.59px;

$titleText: Domaine, serif;
$bodyText: 'Montserrat', sans-serif;
$narrowText: DomaineNarrow, serif;

$blue: #00243F;
$lightGreen: #7BCAD2;
$green: #016D81;
$black: #000000;
$white: #FFFFFF;
$offWhite: #f2f2f2;
$grey1: #979797;
$grey2: #666666;
$grey3: #333333;
$grey4: #D9D9D9;
$red: #EC6C68;
$aquamarine: #016D81;

$shadow: 0 0 12px 6px rgba(0,0,0,0.1);

$columnGutter: 1rem;
$rowGutter: -1rem;

%label {
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  color: $grey3;
  font-weight: 600;
  font-size: 12px;
  transform: translate(0, 1.5px);
  transform-origin: top left;
}

$phone: 767px;
$tabletLand: 981px;
$tablet: 1200px;
$desktop: 1366px;
