@import "../../../styles/variables";

.cardList {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  & > div {
    margin-bottom: 36px;
    order: 0;
    &.not-available {
      order: 1;
    }
  }
}
.filter {
  padding: 40px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: $blue;
  font-weight: 500;
  @media (max-width: $tablet) {
    display: block;
  }
  .filterOptions {
    display: flex;
    min-width: 50%;
    @media (max-width: $tablet) {
      justify-content: center;
      margin-top: 30px;
    }
    @media (max-width: $phone) {
      display: block
    }
  }
  .filterButton {
    margin-left: 20px;
    height: 34px;
    border-radius: 18px;
    border: 1px solid $grey2;
    padding: 4px 15px;
    cursor: pointer;
    position: relative;
    @media (max-width: $phone) {
      margin: 0 0 10px;
    }
    select {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

  }
  .filterSelected {
    background-color: $blue;
    color: $white;
  }
  .filterLabel {
    padding: 4px 0;
    margin-left: 20px;
    @media (max-width: $phone) {
      margin: 0 0 5px;
    }
  }
  .filterCheckbox {
    margin-left: 8px;
    height: 36px;
    width: 60px;
    border-radius: 18px;
    background: #CCCCCC;
    position: relative;
    cursor: pointer;
    @media (max-width: $phone) {
      margin: 0;
    }
    & .icon {
      position: absolute;
      width: 30px;
      height: 30px;
      background: $white;
      border-radius: 50%;
      border: 2px solid $offWhite;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 3px;
      left: 3px;
      & svg {
        display: none;
      }
    }
    & input {
      margin: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      position: relative;
      z-index: 1;
      &:checked ~ div {
        left: auto;
        right: 3px;
        border-color: $blue;
        & svg {
          display: block;
        }
      }
    }
  }
  .tabsWrapper {
    background: $offWhite;
    padding: 4px;
    flex-grow: 1;
    display: flex;
    max-width: 100%;
    overflow-x: auto;
  }
  .tab {
    background-color: transparent;
    z-index: 4;
    min-width: 150px;
    @media (min-width: 1024px) {
      width: 25%;
    }
    & > button {
      font-Size: 14px;
      line-Height: 22px;
      text-transform: none;
      //min-width: 175px;
      padding: 18px 1rem;
      display: block;
      border: none;
      background: transparent;
      font-family: Montserrat, Arial, sans-serif;
      font-weight: 500;
      color: $blue;
      cursor: pointer;
      opacity: 0.7;
      position: relative;
      width: 100%;
      height: 100%;
      &:after {
        position: absolute;
        content: "";
        left: 2px;
        bottom: 4px;
        height: 4px;
        display: block;
        z-index: 2;
        background: transparent;
        width: calc(100% - 4px);
      }
    }
    &.activeTab {
      background-color: $white;
      & > button {
        opacity: 1;
        &:after {
          background: $red;
        }
      }
    }
    &.disabledTab {
      opacity: .3;
      > button {
        cursor: initial;
      }
    }
  }
}
.stars {
  display: flex;
  svg {
    width: 20px;
    height: 24px;
    margin-right: 16px;
    path {
      fill: $grey1;
    }
  }
  .active {
    svg path {
      fill: $white;
    }
  }
}
.popup {
  border-top: 1px solid $grey1;
  padding: 18px 30px;
  max-height: 400px;
  overflow: auto;
  .popupLabel {
    display: block;
    margin-bottom: 20px;
    color: $grey2;
    font-weight: 500;
    font-size: 1rem;
  }
  .popupOptions {
    margin-bottom: 20px;
    & > label {
      display: inline-block;
      width: calc(50% - 4px);
      margin-bottom: 12px;
    }
  }
}
.buttonWrapper {
  display: flex;
  align-items: center;
  padding: 10px 30px 10px;
  border-top: 1px solid #979797;
  a {
    text-decoration: underline;
  }
  button {
    margin: 0;
    margin-left: auto;
    padding: 12px 1rem;
  }
}
.secondaryMenu {
  overflow: hidden;
  .menu {
    width: 100%;
    overflow: auto;
    & > ul {
      display: flex;
      flex-direction: row;
      @media (max-width: $phone) {
        width: 822px;
        display: block;
      }
      & > li {
        flex: 1;
        font-size: 1.125rem;
        line-height: 1.375rem;
        text-align: center;
        position: relative;
        padding: 3px;
        background: rgba(#E5F4F6,0.95);
        margin: 0 1px;
        @media (max-width: $phone) {
          width: 150px !important;
        }
        @media (max-width: $phone) {
          font-size: 1rem;
          //width: 135px;
          display: inline-block;
        }
        > span {
          padding: 14.5px 0;
          display: block;
          color: $blue;
          font-family: Domaine,serif;
          font-weight: 600;
          cursor: pointer;
          border-bottom: 3px solid transparent;
          position: relative;
          svg {
            height: 1rem;
            margin-left: .5rem;
          }
          &:after {
            display: block;
            content: "";
            height: 3px;
            width: 0;
            background-color: $lightGreen;
            top: 100%;
            left: 50%;
            transform: translateX(-50%) scaleX(0);
            transition: all .3s;
            position: absolute;
          }
          &:hover {
            &:after {
              width: 100%;
              left: 50%;
              transform: translateX(-50%) scaleX(1);
            }
          }
        }
        &.active {
          > a, > span {
            border-color: $lightGreen;
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
}
.introContainer {
  position: relative;
  z-index: 1;
}
.intro {
  display: block;
  @media (max-width: $tabletLand) {
    display: flex;
    flex-direction: column-reverse;
  }
}
.introContent {
  width: calc(100% - 346px);
  display: inline-block;
  @media (max-width: $tabletLand) {

    width: 100%;
  }
  ul {
    list-style: disc;
    padding: 0 20px;
    > li {
      > ul {
        list-style: circle;
        > li {
          > ul {
            list-style: square;
          }
        }
      }
    }
  }
  p {
    //margin-left: 50px;
    //max-width: 618px;
    @media (max-width: $tablet) {
      margin-left: 0;
    }
    &.introContentBold{
      margin-left: 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      max-width: 668px;
    }
  }
  .brochure {
    margin: 40px 0 0;
  }
  button {
    margin: 20px 0 40px 50px;
    @media (max-width: $tablet) {
      margin-left: 0;
    }
  }
  &.introContentPackages {
    width: 100%;
    p {
      max-width: 100%;
      margin-right: 50px;
    }
  }
}
.tags {
  position: absolute;
  left: 10%;
  top: calc(100vh - 142px);
  z-index: 1;
  @media (max-width: $tablet) {
    display: none;
  }
  li {
    color: $blue;
    height: 30px;
    border-radius: 15px;
    border: 1px solid $blue;
    background: $white;
    padding: 0 12px;
    display: inline-block;
    line-height: 28px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 400;
  }
}
.details {
  display: flex;
  margin-right: 306px;
  height: 78px;
  margin-top: -78px;
  position: relative;
  z-index: 1;
  background: $white;
  flex-direction: row;
  justify-content: flex-end;
  //justify-content: space-between;
  @media (max-width: $tablet) {
    margin-right: 0;
    width: calc(100% + 64px);
    margin-left: -48px;
    padding: 0 48px;
    overflow: hidden;
  }
  & > div {
    //flex: 1;
    display: flex;
    align-items: center;
    line-height: 16px;
    margin-right: 2rem;
    @media (max-width: $tablet) {
      display: none;
    }
  }
  .map {
    color: $blue;
    flex-grow: 1;
    @media (max-width: $tablet) {
      display: flex;
    }
    & > svg {
      font-size: 28px;
    }
  }
  &:before {
    content: '';
    background: $white;
    height: 100%;
    width: 50vw;
    right: 100%;
    top: 0;
    position: absolute;
    @media (max-width: $tablet) {
      content: none;
    }
  }
  span {
    display: block;
    &:first-of-type {
      font-weight: 600;
    }
  }
}
.number {
  font-size: 36px;
  font-family: $titleText;
  color: $blue;
  font-weight: 600;
}
.detailsContent {
  margin-left: 11px;
  font-size: 12px;
  text-transform: uppercase;
  a {
    text-transform: none;
  }
}
.communityRating {
  svg {
    height: 54px;
    margin-right: 12px;
  }
}
.booking {
  width: 306px;
  flex-shrink: 0;
  margin-left: 40px;
  margin-top: -78px;
  display: inline-block;
  vertical-align: top;
  position: sticky;
  top: 120px;
  z-index: 1;
  padding-bottom: 80px;
  @media (max-width: $tabletLand) {
    display: block;
    width: 100%;
    position: static;
    margin-top: 0;
    margin-left: 0;
  }
  .bookingHeader {
    height: 78px;
    background: $red;
    color: $white;
    font-size: 12px;
    padding: 15px 24px;
    .label {
      font-family: $titleText;
      line-height: 16px;
    }
    .costs {
      display: flex;
      align-items: baseline;
    }
    .price {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-right: 8px;
      span {
        font-size: .8rem;
        font-weight: 500;
      }
    }
    .total {
      font-weight: 500;
      text-decoration: underline;
      margin-bottom: 15px;
      font-size: 14px;
      margin-left: auto;
      display: inline-block;
    }
  }
  .bookingInner {
    padding: 20px 24px;
    .label {
      font-weight: 500;
      margin-bottom: 8px;
      display: block;
    }
    .bookingInput {
      margin-bottom: 24px;
      border: 1px solid $grey1;
      display: flex;
      padding: 6px 0;
      align-items: center;
      input, > span {
        font-size: 13px;
        font-family: $bodyText;
        border: none;
        padding: 10px 9px;
        color: $grey3;
        font-weight: 500;
      }
      .icon {
        width: 44px;
        height: 44px;
        margin-left:auto;
        border-left: 1px solid $grey1;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        svg {
          display: block;
          cursor: pointer;
          width: 24px;
          height: 18px;
          margin-bottom: -5px;
          &:last-child {
            margin-top: -3px;
          }
        }
      }
    }
    .guestInput {
      position: relative;
      padding: 6px 0 6px;
      //position: relative;
    }
    .footer {
      border-top: 1px solid $grey1;
      padding-top: 22px;
      font-weight: 500;
      text-align: center;
      margin-top: 40px;
      position: relative;
      a {
        color: $blue;
        text-decoration: underline;
      }
      .bookingAvailability {
        color: $red;
        text-transform: uppercase;
        font-weight: 400;
        display: block;
      }
      .keys {
        width: 50px;
        position: absolute;
        top: -12px;
        left: 50%;
        background: $white;
        transform: translateX(-50%);
        > svg {
          margin: auto;
        }
      }
    }
  }
}
.bookingOverview {
  .inner {
    background:$white;
    padding: 11px;
    border-radius: 12px;
    box-shadow: 0px 3px 12px 0px rgba(0,0,0,0.1);
  }
}
.discover {
  position:relative;
  & img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    @media (max-width: $phone) {
      height: 300px;
    }
  }
}
.amenities {
  //@media (max-width: $tablet) {
  //  display: none;
  //}
  margin: 36px 60px 36px 0;
  li {
    width: 166px;
    margin: 0 20px 36px 45px;
    display: inline-block;
    position: relative;
    &:before {
      content: '';
      width: 100%;
      height: calc(100% + 24px);
      position: absolute;
      top: -12px;
      left: -33px;
      background: rgba($lightGreen, 0.2);
      padding: 12px 0;
    }
    svg, span:first-of-type {
      position: absolute !important;
      top: 50%;
      left: -33px;
      transform: translateX(-50%) translateY(-50%);
      width: 50px
    }
    span:last-of-type {
      background: $white;
      position: relative;
      padding: 6px 10px;
      font-weight: 500;
      color: $grey3;
      display: inline-block;
      width: 133px;
    }
  }
}

.sectionHeading {
  font-Size: 2rem;
  line-Height: 2.5rem;
  color: $blue;
  @media (min-width: $desktop) {
    font-Size: 2.25rem;
    line-Height: 3rem;
  }
}
.large {
  font-Size: 2.5rem;
  line-Height: 3.25rem;
  @media (min-width: $desktop) {
    font-Size: 3rem;
    line-Height: 3.75rem;
  }
}
.tagLarge,
.tag {
  color: $green;
  font-family: $bodyText;
  font-weight: 500;
  font-Size: 1rem;
  line-Height: 1.1rem;
  text-transform: uppercase;
  display: block;
  letter-spacing: 2px;
}
.tagLarge {
}
.centered {
  text-Align: center;
  &:after {
    left: 50% !important;
    transform: translateX(-50%);
  }
}
.todo {
  padding: 32px 0;
  position: relative;
  & > span:first-child {
    max-width: 100%;
    max-height: 600px;
    height: 250px;
    position: relative !important;
    @media (min-width: $tablet) {
      height: 600px !important;
    }
    > img {
      width: 100%;
      position: relative;
      object-fit: cover;
      max-height: 600px;
    }
  }
}
.infoContainer {
  @media (min-width: $phone) {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: calc(100% - 200px);
  }
}
.info {
  background-color: $white;
  padding: 46px 0 0;
  @media (min-width: $phone) {
    padding: 46px 65px;
  }
}
.love {
  margin: 40px 0 200px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  @media (max-width: $tablet) {
    margin: 0 0 150px;
    display: block;
  }
  div {
    flex: 1;
    margin-right: 20px;
    text-align: right;
    @media (max-width: $tablet) {
      text-align: center;
    }
  }
  p {
    flex: 1;
    margin-left: 20px;
  }
  .loveTag {
    font-weight: 200;
    font-size: 30px;
    line-height: 38px;
    text-transform: none;
  }
  svg {
    display: none;
    @media (min-width: $tablet) {
      display: block;
    }
  }
}
.contourWrapper {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.14;
  overflow: hidden;
  height: 100%;
  width: 100%;
  & svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.insetContainer {
  position: relative;
  margin-bottom: 150px;
  padding-top: 92px;
  @media (max-width: $tabletLand) {
    margin-bottom: 50px;
    padding-top: 0;
  }
}
.insetPanel {
  position: relative;
}
.insetPanelContent {
  background-color: white;
  @media (min-width: $tablet) {
    display: flex;
    flex-direction: row;
  }
}
.insetPanelBackground {
  width: 100%;
  height: 540px;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 35px solid #F2E5D2;
  border-right: 35px solid #F2E5D2;
  @media (min-width: $tablet) {
    max-width: 795px;
  }
  @media (max-width: $tabletLand) {
    position: static;
    border-width: 13px;
    height: auto;
    padding-bottom: 56%;
  }
}
.stickyContainer {
  margin-top: -630px;
  @media (max-width: $tablet) {
    margin-top: 0;
  }
}
.insetPanelBackgroundAlt {
  left: auto;
  right: 0;
  border-right:none;
  border-left: 35px solid $red;
  border-color: $red;
  @media (max-width: $tablet) {
    border-width: 13px;
  }
}
.insetPanelBackgroundBlue {
  border-color: $lightGreen;
}
.insetPanelInner {
  padding: 32px;
  @media (min-width: $desktop) {
    padding: 48px 32px 32px 64px;
  }
}
.insetPanelStatement {
  margin: 32px 0;
  @media (min-width: $desktop) {
    margin: 32px;
  }
  & p:first-of-type {
    @media (min-width: $desktop) {
      margin: 0;
    }
  }
}
.sectionHeading {
  @apply font-serif relative pb-4;
  font-Size: 2rem;
  line-Height: 2.5rem;
  font-weight: 400;
  color: $blue;
  @media (min-width: $desktop) {
    font-Size: 2.25rem;
    line-Height: 3rem;
  }
  &:after {
    background-color: #EC6C68;
    width: 5.2rem;
    height: 2px;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.tagLarge {
  color: $green;
  font-family: $bodyText;
  font-weight: 500;
  font-Size: 1rem;
  line-Height: 1.1rem;
  text-transform: uppercase;
  display: block;
  letter-spacing: 2px;
}
.feelGood{
  width: 100%;
  max-width: 424px;
  margin: 0 auto -80px;
}
.featured,
.availability {
  max-width: 1200px;
  margin: -68px auto 0 auto;
  height: 648px;
  background: rgba(#F2E5D2, 40%);
  & > svg {
    position: absolute;
    top: -95px;
    left: 50%;
    transform: translateX(-50%);
  }
  .allForJust {
    text-align: center;
    font-family: $narrowText;
    color: #333333;
    font-size: 30px;
    font-weight: 900;
  }
  .saving {
    text-align: center;
    color: $green;
    font-size: 24px;
  }
  .enquire {
    border: 1px solid $green;
    padding: 1rem 3rem;
    color: white;
    font-weight: 600;
    background: $green;
    border-radius: 6px;
    cursor: pointer;
    &[disabled] {
      cursor: not-allowed;
      background: #c3c3c3;
      border: 1px solid #c3c3c3;
    }
  }
}
.featured {
  max-width: 804px;
}
.featuredCarousel{
  width: calc(100% + 210px);
  margin-left: -105px;
  padding-top: 75px;
}
.availability {
  background: rgba(#7BCAD2, 20%);
  background: linear-gradient(90deg, rgba(255,255,255,1) 10%, rgba($lightGreen,0.2) 10%, rgba($lightGreen,0.2) 90%, rgba(255,255,255,1) 90%);
  height: auto;
  margin-bottom: 40px;
  padding-top: 20px;
  .availabilityCard {
    display: flex;
    position: relative;
    z-index: 2;
    margin-bottom: 1rem;
    //padding-top: 40px;
    @media (max-width: $tablet) {
      display: block;
      margin: 0;
      width: 100%;
    }
    .gallery {
      position: relative;
      max-width: 392px;
      @media (min-width: $phone) {
        display: table-cell;
        vertical-align: top;
        width: 50%;
      }
      height: 100%;
      .image {
        height: 100%;
      }
      > div > div:nth-child(2) {
        position: relative;
        &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          //box-shadow: inset 0px -40px 100px 0px rgba(0,0,0,1);
        }
      }
      .chevronRight,
      .chevronLeft {
        width: 45px;
        background: none;
        border: 0;
        position: relative;
        padding:0 15px;
        outline: none;
        transition: transform 0.2s ease-out;
        cursor: pointer;
        svg path {
          fill: $white;
        }
        &:hover {
          transform: scale(1.1);
        }
        &.disabled {
          opacity: 0;
        }
      }
      .chevronLeft {
        transform: rotateZ(180deg);
        &:hover {
          transform: rotateZ(180deg) scale(1.1);
        }
      }
      li button {
        svg {
          fill: $white;
        }
      }
    }
    img {
      height:262px;
      object-fit: cover;
      @media (max-width: $tablet) {
        width: 100%;
        height: 295px;
        object-fit: cover;
      }
    }
    .availabilityImage {
      //width: 292px;
      //max-height: 189px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .packageFeatureImage {
      width: 350px;
      //max-height: 189px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .availabilityInner {
      background: $white;
      width: 100%;
    }
    .availabilityHeader {
      @apply font-semibold font-sans text-[22px] text-blue;
      padding: 20px 0 0;
      @media (max-width: $tablet) {
        padding: 20px 20px 0;
      }
    }
    .availabilityContent {
      padding: 0 40px 20px;
      //border-bottom: 1px solid #CCCCCC;
      display: flex;
      flex-direction: row;
      //align-items: center;
      @media (max-width: $tablet) {
        padding: 0 20px 20px;
        display: block;
      }
      > svg {
        width: 25px;
        margin-left: 32px;
      }
      .availabilityInfo {
        flex-grow: 1;
        h2 {
          font-size: 21px;
          font-family: DomaineNarrow,serif;
          font-weight: 700;
        }
        ul {
          list-style: disc;
          padding: 0 20px;
          > li {
            > ul {
              list-style: circle;
              > li {
                > ul {
                  list-style: square;
                }
              }
            }
          }
        }
        ol {
          list-style: decimal;
          padding: 0 20px;
        }
      }
    }
    .availabilityCosts {
      width: 180px;
      flex-shrink: 0;
      margin-left: 32px;
      font-size: 12px;
      color: $blue;
      @media (max-width: $tablet) {
        margin-left: 0;
      }
      .price {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        span {
          font-size: .8rem;
          font-weight: 400;
        }
      }
      .total {
        font-weight: 500;
        text-decoration: underline;
        margin-bottom: 15px;
      }
      &.glance {
        h4 {
          font-size: 18px;
          letter-spacing: 0.15px;
          line-height: 18px;
          color: $blue;
          margin-bottom: 1rem;
        }
        ul {
          li {
            font-weight: 700;
            color: $blue;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0.5rem;
            svg {
              color: $red;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
    .included {
      font-size: 12px;
    }
    .availabilityReadmore {
      //overflow: hidden;
      //display: -webkit-box;
      //-webkit-box-orient: vertical;
      //-webkit-line-clamp: 3;
      strong {
        cursor: pointer;
      }
    }
    .availabilityTable {
      margin: 15px 5px 10px 40px;
      text-align: left;
      max-height: 300px;
      overflow: auto;
      @media (max-width: $tablet) {
        margin: 10px;
      }
      table {
        width: calc(100% - 20px);
        font-size: 12px;
        border-collapse: collapse;
        @media (max-width: $tablet) {
          width: 600px;
        }
        tbody td {
          border-bottom: 1px solid $offWhite;
          padding:8px 0;
        }
        a {
          color: $red;
          text-decoration: underline;
          font-weight: 500;
        }
        button {
          background: $white;
          color: $blue;
          padding: 5px 16px;
          border-radius: 15px;
          border-color: $grey1;
          display: block;
          margin: 0 0 0 auto;
        }
      }
    }
  }
  .roomAccordion {
    z-index: 1;
    //position: relative;
    .roomAccordionItem {
      //padding: 1rem;
      border-top: none;
      margin: 2rem 0;
      background-color: $white;
      box-shadow: 0 0 6px 3px rgba($blue,0.1);
      .roomAccordionHeading {
        .roomAccordionButton {
          border-top: none;
          padding: 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-size: 24px;
          & svg {
            margin-right: 1rem;
          }
          &[aria-expanded="true"] {
            svg {
              transform: rotate(-90deg);
            }
          }
        }
        h2 {
          font-family: $titleText;
          font-weight: 700;
          color: $blue;
          font-size: 28px;
          padding: 1rem;
          span {
            font-size: 0.8rem;
            font-family: $bodyText;
            font-weight: 400;
          }
        }
      }
      .roomAccordionHeadingSelected {
        color: white;
        background: $blue;
        h2 {
          color: white;
        }
      }
      .guestsWrapper {
        position: relative;
        .guests {
          margin: 0 2rem;
          border: 1px solid #979797;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          span {
            cursor: pointer;
            font-family: $titleText;
            font-size: 1rem;
            color: $blue;
            font-weight: 600;
            margin: 0 0.5rem;
          }
          svg {
            font-size: 20px;
            color: $blue;
            width: 20px;
            display: block;
          }
        }

      }
      .roomAccordionTotal {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        //border-bottom: 1px solid $blue;
        padding: 0 0 0 0;
        margin: 0 0 1rem 0;
        color: $blue;
        width: 100%;
        .roomAccordionTotalLabel {
          font-size: 20px;
          font-weight: 400;
          margin: 0 1rem 0 0;
          > p {
            margin: 0;
            text-align: right;
          }
        }
        .roomAccordionTotalPrice {
          font-size: 24px;
          font-weight: 600;
          > p {
            margin: 0;
          }
        }
        .roomAccordionPerPerson {
          font-weight: 400;
          font-size: 1rem;
        }
        .roomAccordionAddToBasket {
          border: 2px solid $green;
          background-color: white;
          padding: 0.75rem 2rem;
          color: $green;
          font-weight: 600;
          cursor: pointer;
          width: 163px;
          &.selectButton {

          }
          &.selectedButton {
            background-color: $green;
            color: $white;
          }
        }
        .guestsWrapper {
          position: relative;
          .guests {
            margin: 10px 0;
            border: 1px solid #979797;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            span {
              cursor: pointer;
              font-family: $titleText;
              font-size: 1rem;
              color: $blue;
              font-weight: 600;
            }
            svg {
              font-size: 20px;
              color: $blue;
              width: 20px;
              display: block;
            }
          }

        }
      }
      .roomAccordionActions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //align-items: center;
        padding-top: 1rem;
        border-top: 1px solid $blue;
        @media (min-width: $phone) {
          flex-direction: row;
        }
        > p {
          margin-right: 1rem;
        }
        .roomAccordionAddToBasket {
          margin-right: 0;
          display: flex;
          flex-direction: row;
          @media (min-width: $phone) {
            //margin-right: 1rem;
          }
          .roomAddToBasket {
            padding: 0.5rem 0;
            line-height: 1.291;
            margin-right: 1rem;
          }
          button:only-child {
            margin: 0 0 0 auto;
          }
        }
        .availabilityCosts {
          flex-grow: 1;
          font-size: 12px;
          color: $blue;
          margin-right: 2rem;
          margin-bottom: 0;
          @media (max-width: $tablet) {
            margin-right: 0;
            margin-bottom: 1rem;
          }
          > div {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 1rem;
            &:last-child {
              margin-bottom: 0;
            }
          }
          span {
            display: block;
            margin-right: 1rem;
          }
          span:first-child {
            width: 105px;
            text-align: right;
          }
          .price {
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            margin-right: 0.5rem;
          }
          span:not(.price) {
            font-size: .8rem;
            font-weight: 400;
          }
          .total {
            font-weight: 500;
            text-decoration: underline;
            margin-bottom: 15px;
          }
          &.glance {
            h4 {
              font-size: 18px;
              letter-spacing: 0.15px;
              line-height: 18px;
              color: $blue;
              margin-bottom: 1rem;
            }
            ul {
              li {
                font-weight: 700;
                color: $blue;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 0.5rem;
                svg {
                  color: $red;
                  margin-right: 0.5rem;
                }
              }
            }
          }
        }
        .roomSelectPrice {
          border: 2px solid $green;
          background-color: white;
          border-radius: 50px;
          padding: 0.5rem 1rem;
          color: $green;
          font-weight: 600;
          font-size: 0.85rem;
          cursor: pointer;
          width: 120px;
          display: block;
          text-align: center;
          flex-grow: 1;
          @media (min-width: $phone) {
            flex-grow: 0;
          }
          &.selectButton {

          }
          &.selectedButton {
            background-color: $green;
            color: $white;
          }
        }
        div {
          &:last-child {
            button {
              border-width: 2px;
            }
          }
        }
      }
    }
  }
}
.viewAll {
  display: block;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
  color: $black;
  font-weight: 500;
  padding: 10px 20px;
  @media (max-width: $tablet) {
    text-align: left;
  }
}
.loadMore {
  margin: 32px auto 0;
  position: relative;
  top: 24px;
  z-index: 1;
  text-align: center;
}
.subheader {
  margin: 60px 0 20px;
  font-size: 1.5rem;
}
.facilities {
  @media (max-width: $phone) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 !important;
  }
}
.facilities li {
  display: inline-block;
  width: 257px;
  margin-top: 8px;
  font-weight: 500;
  @media (max-width: $phone) {
    width: 50%;
  }
  &:before {
    content: '\2713';
    display: inline-block;
    width: 18px;
    height: 18px;
    background: $red;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
    color: white;
    font-size: 10px;
    text-align: center;
    line-height: 1.2rem;
  }
}
.facilitiesAmenities {
  margin: 20px 0 30px;
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
  li {
    width: 40%;
    @media (max-width: $phone) {
      width: 50%;
    }
  }
}
.orderContainer{
  .booking {
    height: 590px;
    left: calc(50% + 286px);
    margin-top: 50px;
    padding: 0;
  }
  .bookingOverview {
    width: 328px;
    left: calc(50% + 275px);
  }
}
.accordionWrapper {
  margin: 5.65rem 0 20px;
}
.accordion {
  //max-width:689px;
  margin: 40px 0 0;
  p{
    margin-left: 0;
  }
}
.watermark {
  width: 360px;
  position: absolute;
  top: calc(50% - 180px);
  left: calc(0% - 250px);
  & svg {
    width: 100%;
  }
}
.testimonials {
  margin-top: 40px;
}
.fullscreen {
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  margin-left: auto;
  svg {
    margin-left: 6px;
    width: 30px;
    height: 30px;
  }
}
.sticky {
  @media (min-width: 1366px) {
    position: sticky;
    top: 130px;
    align-self: flex-start;
    margin-bottom: 2rem;
  }
}
.noResults {
  text-align: center;
  h2 {
    font-size: 2rem;
    margin: 2rem 0;
    font-family: $bodyText;
  }
  .noResultsActions {
    p {
      text-decoration: underline;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
.mapGrid {
  @media (max-width: 1365px) {
    flex-direction: column-reverse;
  }
}
.discoverGallery {
  width: 100%;
  height: 300px;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  @media (min-width: $desktop) {
    height: 350px;
  }
  @media (min-width: 1600px) {
    height: 500px;
  }
  .discoverGalleryInner {
    display: flex;
    height: 100%;
    position: absolute;
  }
  .discoverGalleryItem {
    padding: 0 1rem 0 0;
    width: 80vw;
    &:last-child {
      padding: 0;
    }
    @media (min-width: $phone) {
      width: 25vw;
    }
    &.discoverGallerySingle {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &.discoverGalleryDouble {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      img {
        display: block;
        width: 100%;
        max-height: 50%;
        object-fit: cover;
        &:first-child {
          padding-bottom: 0.5rem;
        }
        &:last-child {
          padding-top: 0.5rem;
        }
      }
    }
  }
}
.additionalAmenities {
  column-count: 3;
  margin-bottom: 2rem;
}
.introFeatures {
  padding: 2rem;
  //height: 100%;
  background-color: $grey4;
  ul {
    li {
      margin: 1rem;
    }
  }
}
