@import "../../../styles/variables";

.wrapper {
  margin: 5.65rem 0;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: 0;
  }
}
.white {
  background-color: white;
}
.transparent {
  background-color: transparent;
}
.offWhite {
  background-color: $offWhite;
  padding: 5.65rem 0;
}
.gold {
  background-color: #FBF5EC;
  padding: 5.65rem 0;
}
.lightGold {
  background-color: rgba(#F2E5D2,0.3);
}
.blue {
  background-color: rgba(123,202,210,0.2);
  padding: 5.65rem 0;
}
.margined {
  margin-left: 14px;
  margin-right: 14px;
}
.inlineTitle {
  margin-top: 200px;
  padding-top: 0;
  & > div > div > div > h2 {
    margin-top: -70px;
  }
}
.noMargin {
  margin: 0;
}
.overflowHidden {
  overflow: hidden;
}
.withPadding {
  padding: 5.65rem 0;
}
.withBottomPadding {
  padding: 0 0 5.65rem 0;
}
.smallMargin {
  margin-top: 1.65rem;
  margin-bottom: 0;
}
.flexGrow {
  flex-grow: 1;
}
.negBottomMargin {
  @media (min-width: $tablet) {
    margin-bottom: -11rem;
  }
}
